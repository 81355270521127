<template>
  <base-card class="available-accesses mt-5">
    <v-card-title class="justify-center">
      <div class="text-center font-weight-bold text-h6 text--lighten-1">
        {{ $t("access.availableAccesses") }}
      </div>
    </v-card-title>

    <v-card-text class="pb-4">
      <v-row class="px-4 pb-2">
        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("order.accessId") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("price.network") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("order.mduApartmentNumber") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("order.mduDistinguisher") }}
        </v-col>
      </v-row>

      <v-radio-group
        v-model="accessId"
        column
        hide-details
        class="mt-0 pt-0"
        @change="chooseAccess"
      >
        <v-row
          v-for="(item, index) in availableAccesses"
          :key="`${index}-access`"
          class="px-4 pb-2"
        >
          <v-col
            cols="3"
            class="py-0"
          >
            <v-radio
              :value="item.accessId"
              :label="item.accessId"
              hide-details
            />
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ $t(`access.${item.network}`) }}
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ item.mduApartmentNumber }}
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ item.mduDistinguisher }}
          </v-col>
        </v-row>
      </v-radio-group>
    </v-card-text>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      availableAccesses: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        accessId: '',
        availableServices: [],
      }
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        setSelectedAccess: 'setSelectedAccess',
        setSelectedStokabPoint: 'setSelectedStokabPoint',
        setStokabProductInfo: 'setStokabProductInfo',
        getAccessDetail: 'getAccessDetail',
      }),

      async chooseAccess() {
        this.setLoading(true)
        this.setLoadingText(this.$t('network.loadingProducts'))

        const access = this.availableAccesses.find(item => item.accessId === this.accessId)
        this.setSelectedAccess(access)

        this.setSelectedStokabPoint(null)
        this.setStokabProductInfo(null)

        try {
          const payload = {
            type: access.network,
            accessId: access.accessId,
            networkCategory: 'residential',
          }

          const response = await this.getAccessDetail(payload)

          if (!response || !response.services) {
            this.$emit('set-products-services', [])
            showErrorDialog(this.$t('order.noAvailableService'))
            this.setLoading(false)
            return
          }

          this.availableServices = response.services.filter(item => item.connection !== 'NO' || item.available !== 'NO')
          const products = response.products || []

          if (products.length === 0) {
            this.$emit('set-products-services', [])
            showErrorDialog(this.$t('order.noAvailableService'))
            this.setLoading(false)
            return
          }

          this.$emit('set-products-services', {
            services: this.availableServices,
            products,
          })
        } catch (error) {
          this.$emit('set-products-services', [])
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },
    }
  }
</script>

<style lang="scss">
  .available-accesses {
    max-width: 900px !important;
    margin: 0 auto;
  }
</style>
