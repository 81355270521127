<template>
  <base-card class="available-accesses mt-5">
    <v-card-title class="justify-center">
      <div class="text-center font-weight-bold text-h6 text--lighten-1">
        {{ $t("access.stokabPoints") }}
      </div>
    </v-card-title>

    <v-card-text class="pb-4">
      <v-row class="px-4 pb-2">
        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("access.pointName") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("common.type") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("access.state") }}
        </v-col>

        <v-col
          cols="3"
          class="py-0 text-16"
        >
          {{ $t("access.fiberStatus") }}
        </v-col>
      </v-row>

      <v-radio-group
        v-model="stokabPointId"
        column
        hide-details
        class="mt-0 pt-0"
        @change="chooseStokabPoint"
      >
        <v-row
          v-for="(item, index) in stokabPoints"
          :key="`${index}-point`"
          class="px-4 pb-2"
        >
          <v-col
            cols="3"
            class="py-0"
          >
            <v-radio
              :value="item.pointId"
              :label="item.pointId"
              hide-details
            />
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ util.getStokabTypeName(item.type) }}
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ item.state }}
          </v-col>

          <v-col
            cols="3"
            class="py-0"
          >
            {{ item.fiberStatus }}
          </v-col>
        </v-row>
      </v-radio-group>
    </v-card-text>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      stokabPoints: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        stokabPointId: '',

        util: util,
      }
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        setSelectedAccess: 'setSelectedAccess',
        setSelectedStokabPoint: 'setSelectedStokabPoint',
        setStokabProductInfo: 'setStokabProductInfo',
        getStokabNetworks: 'getStokabNetworks',
      }),

      async chooseStokabPoint() {
        this.setLoading(true)
        this.setLoadingText(this.$t('network.loadingProducts'))
        this.setSelectedAccess(null)
        this.setSelectedStokabPoint(null)
        this.setStokabProductInfo(null)

        try {
          const pointItem = this.stokabPoints.find(item => item.pointId === this.stokabPointId)

          const payload = {
            pointId: pointItem.pointId,
            type: pointItem.type,
            networkCategory: 'residential',
          }

          const response = await this.getStokabNetworks(payload)

          this.setSelectedStokabPoint(pointItem)
          this.setStokabProductInfo(response.product)
          this.$emit('set-stokab-products', response.productList)
        } catch (error) {
          this.$emit('set-stokab-products', [])
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }

        this.setLoading(false)
      },
    }
  }
</script>

<style lang="scss">
  .available-accesses {
    max-width: 900px !important;
    margin: 0 auto;
  }
</style>
