<template>
    <base-card class="check-availability">
      <v-card-title class="justify-center">
        <div class="text-center font-weight-bold text-h6 yellow--text text--lighten-1">
          {{ $t("address.checkAvailability") }}
        </div>
      </v-card-title>

      <v-card-text class="pb-10">
        <v-autocomplete
          v-model="streetName"
          :items="streetCityList"
          :loading="isLoading"
          :search-input.sync="searchStreet"
          color="white"
          class="mb-4"
          item-text="value"
          item-value="value"
          :placeholder="`${$t('common.pleaseInput')} ${$t(
              'address.streetName'
            ).toLowerCase()}`"
          prepend-icon="mdi-map-marker"
          :append-icon="null"
          return-object
          hide-no-data
          hide-details
          clearable
          :filter="() => true"
          v-if="searchStep === 1"
        >
          <template v-slot:label>
            <span class="yellow--text text--lighten-1">
              {{ $t("address.streetName") }}*
            </span>
          </template>
          <template v-slot:selection="data">
            <span class="white--text">
              {{ data.item.value }}
            </span>
          </template>
          <template v-slot:item="data">
            {{ data.item.value }}
          </template>
        </v-autocomplete>

        <template v-else>
          <v-autocomplete
            v-model="streetNumber"
            :items="fullAddressList"
            :loading="isLoading"
            :search-input.sync="searchFullAddress"
            color="white"
            item-text="value"
            item-value="value"
            :placeholder="formatStreetCity(address)"
            :append-icon="null"
            prepend-icon="mdi-map-marker"
            return-object
            hide-details
            hide-no-data
            clearable
            @click:clear="resetData"
          >
            <template v-slot:label>
              <span class="yellow--text text--lighten-1">
                {{ $t("address.streetNumber") }}
              *</span>
            </template>
            <template v-slot:selection="data">
              <span class="white--text">
                {{ address.street }} {{ data.item.value }},
                {{ address.postalCode }} {{ address.locality }}
              </span>
            </template>
            <template v-slot:item="data">
              {{ data.item.value }}
            </template>
          </v-autocomplete>
        </template>
      </v-card-text>
    </base-card>
</template>

<script>
import { mapActions } from 'vuex'
import * as _ from 'lodash'

import util from '@/utils'
import { showErrorDialog } from '@/utils/swalDialog'

export default {
  data() {
    return {
      isLoading: false,
      address: {
        street: '',
        locality: '',
        postalCode: '',
        streetNumber: '',
        streetLetter: '',
      },
      networkCategory: 'residential',
      streetName: '',
      searchStreet: null,
      streetCityList: [],

      streetNumber: '',
      searchFullAddress: null,
      fullAddressList: [],
      searchStep: 1,
    }
  },

  watch: {
    searchStreet: {
      handler: _.debounce(function (value) {
        if (this.isLoading || !value || value.length === 0) {
          this.streetCityList = []
          return
        }

        this.getSuggestionListWithStreetAndCity(value)
      }, 500),
    },

    searchFullAddress (value) {
      this.getSuggestionListWithFullAddress(value)
    },

    streetName(value) {
      if (!value) {
        this.streetCityList = []
        return
      }
      this.chooseStreetAndCity(value)
    },

    streetNumber(value) {
      if (!value) {
        this.fullAddressList = []

        if (this.searchStep === 2) {
          this.getSuggestionListWithFullAddress(value)
        }
        return
      }
      this.chooseFullAddress(value)
    },
  },

  methods: {
    ...mapActions({
      getSuggestionAddress: 'getSuggestionAddress',
      getAccessAndProducts: 'getAccessAndProducts',
      setLoading: 'setLoading',
      setLoadingText: 'setLoadingText',
    }),

    async searchProducts() {
      this.setLoading(true)
      this.setLoadingText(this.$t("network.loadingProducts"))
      try {
        const payload = {
          street: this.address.street,
          streetNumber: this.address.streetNumber,
          streetLetter: this.address.streetLetter,
          locality: this.address.locality,
          postalCode: this.address.postalCode,
          networkCategory: this.networkCategory,
        }

        const response = await this.getAccessAndProducts(payload)

        this.$emit('set-search-result', response)
        this.$emit('set-search-type', this.networkCategory)
        this.$emit('set-search-address', this.address)
      } catch (error) {
        const errorText = util.getErrorResponse(error)
        showErrorDialog(this.$t('common.error'), errorText)
      }
      this.setLoading(false)
    },

    async getSuggestionListWithStreetAndCity(queryString) {
      const payload = {
        query: queryString,
        columns: 'street,locality',
      }

      this.isLoading = true
      try {
        const response = await this.getSuggestionAddress(payload)
        this.streetCityList = response.rows.map(item => ({
          value: this.formatStreetCity(item),
          locality: item.locality,
          street: item.street,
        }))
      } catch (error) {
        this.streetCityList = []
      }
      this.isLoading = false
    },

    async getSuggestionListWithFullAddress(queryString, cb) {
      const payload = {
        query: this.address.street,
        locality: this.address.locality,
        streetNumber: queryString,
        columns: 'street,locality, postalcode,street_number,letter',
      }

      this.isLoading = true
      try {
        const response = await this.getSuggestionAddress(payload)
        this.fullAddressList = response.rows.map(item => ({
          ...item,
          value: this.formatStreetNumber(item),
        }))
      } catch (error) {
        this.fullAddressList = []
      }
      this.isLoading = false
    },

    formatStreetCity(result) {
      return `${result.street}, ${result.locality}`
    },

    formatStreetNumber(result) {
      return result.letter
        ? `${result.street_number} ${result.letter}`
        : result.street_number
    },

    chooseStreetAndCity(result) {
      this.address = {
        street: result.street,
        locality: result.locality,
      }

      this.searchStep = 2

      this.getSuggestionListWithFullAddress('')
    },

    chooseFullAddress(result) {
      this.address = {
        ...this.address,
        postalCode: result.postalcode,
        streetNumber: result.street_number,
        streetLetter: result.letter,
      }

      this.searchProducts()
    },

    resetData() {
      this.isLoading = false
      this.searchStep = 1
      this.address = {
        street: '',
        locality: '',
        postalCode: '',
        streetNumber: '',
        streetLetter: '',
      }
      this.networkCategory = 'residential'
      this.streetName = ''
      this.streetNumber = ''
      this.$emit('set-search-result', null)
    },
  },
}
</script>

<style lang="scss">
.v-card.check-availability {
  width: 800px;
  height: fit-content;
  position: relative;
  margin: 0 auto;
  background-color: rgba(29, 50, 100, 0.8) !important;
  border-radius: 10px;
  border: 2px solid #fff;

  .v-input__icon.v-input__icon--prepend i {
    color: #FFF !important;
  }

  .v-input__append-inner .v-input__icon.v-input__icon--append i {
    color: #FFF !important;
  }

  .v-select__selections input {
    color: #FFF;
  }

  .v-select__selections input::placeholder {
    color: #FFF;
    opacity: .5;
  }

  .v-select__selections input:-ms-input-placeholder {
    color: #FFF;
    opacity: .5;
  }

  .v-select__selections input::-ms-input-placeholder {
    color: #FFF;
    opacity: .5;
  }

  .v-input__control .v-input__slot::before {
    border-bottom: 1px solid #FFF !important;
  }

  .v-input--radio-group__input {
    justify-content: space-evenly;
  }

  .v-input__icon.v-input__icon--clear button {
    color: #FFF;
  }
}

.v-autocomplete__content {
  margin-top: 5px;
  border-radius: 5px;
}
</style>
