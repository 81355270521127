<template>
  <div class="products-card-wrapper py-6">
    <v-row class="align-center">
      <v-col
        v-for="(item, index) in productList"
        :key="index"
        cols="12" sm="6" md="4" xl="3"
      >
        <base-card>
          <v-card-text class="px-4 px-sm-6 px-md-10">
            <div class="text-center text-h5 font-weight-bold">
              {{ item.serviceName || `${item.speed} Mbit/s` }}
            </div>
            <div class="text-center text-subtitle-1 font-weight-bold purple--text">
              {{ item.network }} / {{ item.type }}
            </div>
            <div class="text-subtitle-1 font-weight-bold mt-2">
              <span class="red--text">{{ item.price }}</span> kr/month
            </div>
            <div class="text-subtitle-1 font-weight-bold">
              <span class="red--text">{{ item.start }}</span> kr start fee
            </div>
            <div class="text-subtitle-1 font-weight-bold">
              <span>{{ $t("price.term") }}:</span> {{ item.term ? `${item.term} months` : "None" }}
            </div>
            <v-btn
              block
              color="primary"
              class="mt-4"
              @click="orderProduct(item)"
            >
              {{ $t("order.order") }}
            </v-btn>
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import util from '@/utils'
import { showErrorDialog } from '@/utils/swalDialog'

export default {
  props: {
    productList: {
      type: Array,
      default: () => [],
    },

    address: {
      type: Object,
      default: () => null,
    },

    availableServices: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    ...mapActions({
      setServiceAddress: 'setServiceAddress',
      setNetworkInfo: 'setNetworkInfo',
      setAvailableService: 'setAvailableService',
    }),

    orderProduct(item) {
      this.setServiceAddress(this.address)
      this.setNetworkInfo(item)

      const selectedService = this.availableServices.find(serviceItem => serviceItem.service === item.serviceName || serviceItem.service === item.serviceAlias)
      this.setAvailableService(selectedService)

      this.$router.push('/order?business=residential')
    },
  },
}
</script>

<style lang="scss">
.products-card-wrapper {
  width: 90%;
  margin: 0 auto;

  .product-item {
    padding: 20px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    margin: 10px 0;

    .speed {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }

    .network-type {
      text-align: center;
      font-size: 16px;
      color: #076618;
      font-weight: bold;
    }

    .monthly-cost {
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;
    }

    .start-cost {
      font-size: 16px;
      font-weight: bold;
    }

    .term {
      font-size: 16px;
      font-weight: bold;
    }

    .order-button {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 0;
    }
  }
}
</style>
