<template>
  <v-app class="home-page">
    <div>
      <div class="navbar-wrapper">
        <div class="logo">
          <img
            src="@/assets/images/logo.png"
            alt=""
          >
        </div>
        <div class="menu-items">
          <v-btn
            color="primary"
            to="/signin"
          >
            {{ $t("userAuth.login") }}
          </v-btn>
        </div>
      </div>

      <div class="main-content">
        <div class="hero-section">
          <check-availability
            v-if="!isMainWebsite"
            @set-search-result="setSearchResult"
            @set-search-type="setSearchType"
            @set-search-address="setSearchAddress"
          />
        </div>

        <available-accesses
          v-if="isSearched && availableAccesses.length > 0"
          :available-accesses="availableAccesses"
          @set-products-services="setProductsServices"
        />

        <available-stokab-points
          v-if="isSearched && stokabPoints.length > 0"
          :stokab-points="stokabPoints"
          @set-stokab-products="setStokabProducts"
        />

        <products-card
          v-if="isSearched"
          :address="address"
          :product-list="availableProductList"
          :available-services="availableServices"
        />
      </div>
    </div>
  </v-app>
</template>

<script>
  import CheckAvailability from '../components/CheckAvailability.vue'
  import ProductsCard from '../components/ProductsCard.vue'
  import AvailableAccesses from '../components/AvailableAccesses.vue'
  import AvailableStokabPoints from '../components/AvailableStokabPoints.vue'

  import util from '@/utils'
  import { showWarningDialog } from '@/utils/swalDialog'

  export default {
    name: 'Homepage',

    metaInfo: {
      title: 'Homepage',
    },

    components: {
      CheckAvailability,
      ProductsCard,
      AvailableAccesses,
      AvailableStokabPoints,
    },

    data () {
      return {
        isMainWebsite: false,
        isSearched: false,
        searchType: '',
        productList: [],
        availableProductList: [],
        availableAccesses: [],
        availableServices: [],
        stokabPoints: [],
        priceInfo: null,
        address: {
          street: '',
          locality: '',
          postalCode: '',
          streetNumber: '',
          streetLetter: '',
        },
      }
    },

    mounted () {
      const websiteUrl = util.getWebsiteUrl()
      this.isMainWebsite = websiteUrl.startsWith(util.publicURL)
      localStorage.removeItem('access_token')
    },

    methods: {
      setSearchResult (result) {
        this.isSearched = false
        this.productList = []
        this.availableProductList = []
        this.availableAccesses = []

        if (!result) {
          return
        }

        this.priceInfo = result

        if (result.stokab) {
          this.isSearched = true
          this.stokabPoints = result.stokab.availablePoints || []
        }

        if (result.sollentuna) {
          this.isSearched = true

          const availableSollentunaAccesses = (result.sollentuna?.rows || []).map(item => ({
            ...item,
            network: 'sollentuna',
          }))
          this.availableAccesses.push(...availableSollentunaAccesses)
        }

        if (result.openinfra) {
          this.isSearched = true

          const availableOpeninfraAccesses = (result.openinfra?.rows || []).map(item => ({
            ...item,
            network: 'openinfra',
          }))
          this.availableAccesses.push(...availableOpeninfraAccesses)
        }

        if (result.openuniverse) {
          this.isSearched = true

          const availableOpenuniverseAccesses = (result.openuniverse?.rows || []).map(item => ({
            ...item,
            network: 'openuniverse',
          }))
          this.availableAccesses.push(...availableOpenuniverseAccesses)
        }

        if (result.ipOnly) {
          this.isSearched = true

          const availableIpOnlyAccesses = (result.ipOnly?.rows || []).map(item => ({
            ...item,
            network: 'ipOnly',
          }))
          this.availableAccesses.push(...availableIpOnlyAccesses)
        }

        if (result.zitius) {
          this.isSearched = true

          const availableZitiusAccesses = (result.zitius?.rows || []).map(item => ({
            ...item,
            network: 'zitius',
          }))
          this.availableAccesses.push(...availableZitiusAccesses)
        }

        if (!this.isSearched) {
          showWarningDialog(this.$t('address.cantDeliveryProduct'))
        }
      },

      setSearchType (type) {
        this.searchType = type
      },

      setSearchAddress (addres) {
        this.address = addres
      },

      setProductsServices (data) {
        this.availableServices = data.services
        this.availableProductList = data.products
      },

      setStokabProducts (productList) {
        this.availableProductList = productList
      },
    },
  }
</script>

<style lang="scss">
.home-page {
  background: #f2f3f8 !important;
  .navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #fff;
    position: fixed;
    padding: 10px 100px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    z-index: 9999;

    .logo img {
      height: 60px;
    }

    .menu-item {
      min-width: 100px;
      text-align: center;
    }
  }

  .main-content {
    padding-top: 70px;
  }

  .hero-section {
    background-image: url("../../assets/images/hero.jpeg");
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>
